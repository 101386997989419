import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="footerMain maxContent">
        <img
          src={require("../assets/logo_white.svg").default}
          style={{ width: 180 }}
          alt=""
        />
        <div
          className="flex mt2"
          style={{ gap: 16, flexWrap: "wrap", justifyContent: "center" }}
        >
          <a href="/contact">Contact</a>
          {/* <a href="/">Portfolio</a> */}
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms-and-conditions">Terms & Conditions</a>
        </div>
        <div className="socials">
          <a
            href="https://wa.me/+8801601040108"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={require("../assets/whatsapp.png")} alt="whatsapp" />
          </a>
          <a href="/">
            <img src={require("../assets/facebook.png")} alt="facebook" />
          </a>

          <a href="/">
            <img src={require("../assets/twitter.png")} alt="twitter" />
          </a>
        </div>
      </div>
      <div className="p1 flex alignCenter justifyCenter">
        <p style={{ textAlign: "center" }}>
          © 2019-2023 Bcommerce - All Rights Reserved
        </p>
      </div>
    </footer>
  );
}

import { AnimatePresence, m } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function Navbar() {
  const [show, setShow] = useState(null);
  const [scroll, setScroll] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 200) {
      setScroll(false);
    }
    if (position < 50) {
      setScroll(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      style={!scroll ? { boxShadow: "0px 0px 10px rgba(0, 0, 85, 0.12)" } : {}}
    >
      {show && (
        <div
          onClick={() => {
            setShow(false);
          }}
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            // backgroundColor: "rgba(0,0,0,0.15)",
          }}
        ></div>
      )}
      <div className="navItemContainer maxContent">
        <Link to="/" className="flex alignCenter">
          <img
            className="logo"
            src={require("../assets/logo.svg").default}
            alt=""
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        </Link>

        <div className="flex alignCenter navLinks">
          <div className="navList">
            {/* <div
              className="serviceNav item"
              onClick={() => {
                setShow("services");
              }}
            >
              Services
              <AnimatePresence>
                {show === "services" && (
                  <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 100, opacity: 0 }}
                  >
                    <div
                      className="hoverList"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <Link to={"/academics"} className="item">
                        <img
                          src={require("../assets/dropshipping.png")}
                          alt=""
                        />
                        DropShipping
                      </Link>
                      <Link to={"/management"} className="item">
                        <img src={require("../assets/ecommerce.png")} alt="" />
                        Ecommerce
                      </Link>
                      <Link to={"/management"} className="item">
                        <img src={require("../assets/api.png")} alt="" />
                        1688 Api
                      </Link>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div> */}
            {/* <Link to={"/portfolio"}>Portfolio</Link> */}
            {/* <Link to={"/about"}>About</Link> */}
          </div>
          <Link to={"/contact"}>
            <button
              className="bt"
              style={{
                background: "linear-gradient(90deg, #007C7C 24%, #009898 100%)",
                color: "#fff",
                padding: "0 1.5rem",
                fontSize: 15,
              }}
            >
              Contact
            </button>
          </Link>
        </div>
      </div>
    </nav>
  );
}

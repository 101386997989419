import "./App.css";
import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const Portfolio = lazy(() => import("./pages/Portfolio"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Terms = lazy(() => import("./pages/Terms"));

function App() {
  const client = window.screen.width > 768 ? "web" : "app";
  const [show, setshow] = useState(client === "web" ? false : true);
  const [trigger, settrigger] = useState(false);

  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={<div className="pageProgress"></div>}>
          <div className="App">
            <div>
              <header>
                <Navbar state={{ settrigger, setshow, show }} />
              </header>
              <main>
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/contact" element={<Contact />} />
                  <Route exact path="/portfolio" element={<Portfolio />} />
                  <Route exact path="/privacy-policy" element={<Privacy />} />
                  <Route
                    exact
                    path="/terms-and-conditions"
                    element={<Terms />}
                  />
                  <Route
                    path="*"
                    element={
                      <div
                        style={{
                          height: "calc(100vh - 78px)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#fff",
                        }}
                      ></div>
                    }
                  />
                </Routes>
              </main>
              <Footer />
            </div>
          </div>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
